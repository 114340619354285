<template>
  <div>
    <div class="clients">
      <mdb-container>
        <mdb-row class="mt-5 pt-5">
          <mdb-col md="12" class="text-center" data-aos="fade-in">
            <h1 class="h1-reponsive white-text">
              <strong>Nuestros clientes</strong>
            </h1>
            <hr class="hr-light mt-4" />
            <h5 class="mb-5 white-text">Cercas & Mallas</h5>
            <mdb-btn outline="white" @click.native="scroll()">testimonios</mdb-btn>
            <mdb-btn outline="white" tag="a" href="https://api.whatsapp.com/send?phone=52744%20254%202295">Contactanos</mdb-btn>
          </mdb-col>
        </mdb-row>
      </mdb-container>
    </div>
    <mdb-container class="container my-5">
      <section>
        <div class="text-center">
          <h1 class="font-weight-bold">Testimonios</h1>
        </div>
        <div class="row justify-content-center mt-4 mx-3">
          <div
            class="col-md-3 mx-3 my-3"
            v-for="(testimonial, i) in testimonials"
            :key="i"
          >
            <mdb-testimonial>
              <mdb-avatar
                wrapperClass="z-depth-1 mb-4"
                :width="200"
                circle
                tag="img"
                :src="testimonial.img"
              />

              <h4 class="font-weight-bold mt-4">{{ testimonial.name }}</h4>
              <h6 class="blue-text font-weight-bold my-3">
                {{ testimonial.position }}
              </h6>
              <p class="font-weight-normal">
                <mdb-icon icon="quote-left" class="pr-2"></mdb-icon>
                {{ testimonial.quote }}
              </p>
              <div>
                <mdb-rating
                  :value="testimonial.rating"
                  disabled
                  far
                  iconActiveClass="orange-text"
                />
              </div>
            </mdb-testimonial>
          </div>
        </div>
      </section>
      <section class="my-5 pt-2 mx-2">
        <div class="text-center mb-5">
          <h1 class="font-weight-bold">Trabajos realizados</h1>
        </div>
        <mdb-container class="mt-5">
          <div class="row justify-content-center">
            <mdb-btn
              @click.native="show = 'all'"
              outline="black"
              class="filter"
              dark-waves
              >Todos</mdb-btn
            >
            <mdb-btn
              @click.native="show = 'malla'"
              outline="black"
              class="filter"
              dark-waves
              >Malla Ciclonica</mdb-btn
            >
            <mdb-btn
              @click.native="show = 'concertina'"
              outline="black"
              class="filter"
              dark-waves
              >Concertina</mdb-btn
            >
            <mdb-btn
              @click.native="show = 'metal'"
              outline="black"
              class="filter"
              dark-waves
              >Metal desplegado</mdb-btn
            >
            <mdb-btn
              @click.native="show = 'reja'"
              outline="black"
              class="filter"
              dark-waves
              >Reja de acero</mdb-btn
            >
          </div>
          <transition name="fade">
          <images v-if="show == 'all'" class="mt-4" product="Todos" />
          </transition>
          <transition name="fade">
          <images v-if="show == 'malla'" class="mt-4" product="Malla ciclónica galvanizada" />
          </transition>
          <transition name="fade">
          <images v-if="show == 'concertina'" class="mt-4" product="Concertina" />
          </transition>
          <transition name="fade">
          <images v-if="show == 'metal'" class="mt-4" product="Metal desplegado" />
          </transition>
          <transition name="fade">
          <images v-if="show == 'reja'" class="mt-4" category="Reja de acero" />
          </transition>
        </mdb-container>
      </section>
      <section>
        <mdb-container class="container">
          <section class="text-center my-5">
            <h2 class="h1-responsive font-weight-bold my-5">
              ¿Por qué somos tan buenos?
            </h2>
            <p class="lead dark-grey-text w-responsive mx-auto mb-5">
              Grupo Empresarial formado por Cercas y Mallas Acapulco, Cercas y
              Mallas Veracruz, Córdoba y Xalapa, Cercas y Mallas Chilpancingo,
              Cercas y Mallas Zihuatanejo, Cercas, Mallas Cd. de México, DF,
              Tlaxcala, Oaxaca y Villa Hermosa. Siempre adaptados a la
              tecnología en materia de seguridad patrimonial para llevar los
              productos de la más alta calidad y resistencia.
            </p>
            <mdb-row>
              <mdb-col md="4">
                <mdb-icon icon="tools" size="3x" class="orange-text" />
                <h5 class="font-weight-bold my-4">Dedicación</h5>
                <p class="dark-grey-text mb-md-0 mb-5">
                  En Cercas y Mallas Versátiles nos dedicamos a la Venta,
                  Instalación, Mantenimiento y Reparación de todo tipo de Cercas
                  de Malla Galvanizada o Ciclónica, Cercas de Alambre, Cercas
                  Energizadas, Cercas de Cuchillas, Cercas de Púas, Cercas de
                  Seguridad, Cercas de Reja Versátil, Cercas Ganaderas, todo
                  tipo de Protección Perimetral, Automatización de Puertas para
                  Cocheras, Venta y Renta de todo tipo de Toldos, Lonas y
                  Mallas.
                </p>
              </mdb-col>
              <mdb-col md="4">
                <mdb-icon icon="map-marked" size="3x" class="orange-text" />
                <h5 class="font-weight-bold my-4">Ubicación</h5>
                <p class="dark-grey-text mb-md-0 mb-5">
                  En Cercas y Mallas Versátiles instalamos Cercas en gran parte
                  del Centro-Sur de la República Mexicana desde nuestros
                  talleres en: Acapulco, Chilpancingo y Zihuatanejo en el Estado
                  de Guerrero, en Veracruz, Xalapa y Córdoba en el Estado de
                  Veracruz, así como en la Ciudad de México, DF, Tlaxcala,
                  Oaxaca y Villa Hermosa.
                </p>
              </mdb-col>
              <mdb-col md="4">
                <mdb-icon icon="headset" size="3x" class="orange-text" />
                <h5 class="font-weight-bold my-4">Servicio</h5>
                <p class="dark-grey-text mb-md-0 mb-5">
                  Solicítenos un Presupuesto GRATIS llamado sin costo para usted
                  a lteléfono 018007279437 o por Radio teléfono Nextel marcando
                  I.D. 32*131054*219 / I.D. 62*138797*1 ó mándenos un e-mail si
                  desea información a detalle de nuestros productos o servicios.
                </p>
              </mdb-col>
            </mdb-row>
          </section>
        </mdb-container>
      </section>
      <section>
        <div class="container">
          <div class="text-center mb-5">
            <h1 class="font-weight-bold">Nuestros trabajos</h1>
          </div>
          <mdb-carousel
            :items="videoCarousel"
            indicators
            controlls
            :interval="8000"
          ></mdb-carousel>
        </div>
      </section>
      <section>
         <div class="text-center mt-5 mb-3 pt-4">
              <h1 class="font-weight-bold">Algunos de nuestros clientes</h1>
         </div>
          <div class="row justify-content-center align-items-center">
              <div class="col-md-3 mx-5">
                  <img src="../assets/ado.png" style="width: 90%" class="img-fluid" alt="">
              </div>
               <div class="col-md-3 mx-5">
                  <img src="../assets/guardia.png" style="width: 70%" class="img-fluid" alt="">
              </div>
               <div class="col-md-3 mx-3">
                  <img src="../assets/hein.jpg" style="width: 70%" class="img-fluid" alt="">
              </div>
          </div>
      </section>
    </mdb-container>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbTestimonial,
  mdbAvatar,
  mdbIcon,
  mdbCarousel,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbRating,
} from "mdbvue";
import images from "../components/imagenes";

export default {
  name: "TestimonialsMultiPage",
  components: {
    mdbContainer,
    mdbTestimonial,
    mdbAvatar,
    mdbIcon,
    mdbCarousel,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbRating,
    images,
  },
  data() {
    return {
      show: "all",
      testimonials: [
        {
          img: require("../assets/axel2.jpeg"),
          quote:
            "Mi casa quedó muy bien al instalar una cerca de malla ciclónica , los recomiendo ampliamente!!",
          name: "Axel Herrera",
          rating: 5,
        },
        {
          img: require("../assets/david.jpeg"),
          quote:
            "Buena atención. Responsables con las entregas .. Lo recomiendo",
          position: "Buen surtido en materiales.",
          name: "David Hernández",
          rating: 4,
        },
        {
          img: require("../assets/uri.jpeg"),
          quote: "Para instalar son muy buenos, eficientes y capaces. ",
          name: "Uri Torruco",
          rating: 4,
        },
        {
          img: require("../assets/axel.jpeg"),
          quote: "Buenos productos",
          name: "Hugo Gutiérrez ",
          rating: 5,
        },
        {
          img: require("../assets/miriam.jpeg"),
          quote:
            "Muy agradecidos con el buen servicio el personal super amables muy contentos con nosotros y una gran gerencia muy amable y servicial.",
          name: "Miriam Rivera",
          rating: 4,
        },
        {
          img: require("../assets/minerva.jpeg"),
          quote:
            "Hola buen día, quiero recomendar ampliamente a Grupo versátil, un excelente precio, muy amables, muy organizados, todo perfecto!",
          name: "Minerva E.",
          rating: 5,
        },
      ],
      videoCarousel: [
        {
          video: true,
          src: require("../assets/c1.mp4"),
          loop: true,
          auto: true,
          muted: true,
        },
        {
          video: true,
          src: require("../assets/c2.mp4"),
          loop: true,
          auto: true,
          muted: true,
        },
        {
          video: true,
          src: require("../assets/c3.mp4"),
          loop: true,
          auto: true,
          muted: true,
        },
      ],
    };
  },
  methods: {
    scroll() {
      window.scrollTo(0, 600);
    },
  },
};
</script>

<style scoped>
.clients {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/malla-main.jpg") no-repeat center fixed;
  background-size: cover;
  height: 100vh;
  color: #e4e4e4;
  padding-top: 10%;
}
</style>